
import ClusterMap from '@/maps/clusterMap'
import { useStore } from 'vuex'
import { onMounted, inject } from 'vue'
import { KavelKaartenGroupedResponse, KavelKaartItem } from "@/dataTypes";
import { KavelKaarten } from '@/core/kavelKaarten'

export default {
  setup () {
    const store = useStore()

    onMounted(async () => {
      const kv = new KavelKaarten()
      const locations: Promise<KavelKaartenGroupedResponse> = kv.getLocations()
      const maps: Promise<ClusterMap> = inject('maps')!

      maps.then(async (map: ClusterMap) => {
        await map.init(document.getElementById('map')!)

        locations.then((groups: KavelKaartenGroupedResponse) => {
          for (const groupId in groups) {
            store.commit({
              type: 'addFilterGroup',
              groupName: groups[groupId].name,
              color: groups[groupId].color
            })
            store.commit({
              type: 'addMarkers',
              items: groups[groupId].kaarten,
              groupName: groups[groupId].name
            })
            const markers: any[] = parseKaarten(groups[groupId].kaarten)
            map.createMarkerGroup(groups[groupId].name, groups[groupId].color, markers)
          }
          store.dispatch('sortMarkers')
          window.dispatchEvent(new Event('groups-loaded'))
        })

        const gmap: google.maps.Map = map.getMap()
        google.maps.event.addListener(gmap, 'idle', function() {
            const viewportMarkers: string[] = map.getMarkersInViewport()
            store.dispatch('setActiveMarkers', viewportMarkers)
        });
      })
    })

    function parseKaarten(kaarten: KavelKaartItem[] | false): any[] {
      const markers: any[] = []
      if (kaarten) {
        kaarten.forEach((item: KavelKaartItem) => {
          const LatLng = new google.maps.LatLng({
            lat: parseFloat(item.centerLat),
            lng: parseFloat(item.centerLong)
          })
          markers.push({
            LatLng: LatLng,
            kaart_id: item.kaart_id
          })
        })
      }
      return markers
    }
  }
}
