import { Renderer, Cluster, ClusterStats } from '@googlemaps/markerclusterer'
/**
 * Renderer for marker clusterer
 * @see https://googlemaps.github.io/js-markerclusterer/interfaces/Renderer.html
 */
export default class ClusterRenderer implements Renderer {
  private defaultColor = '#888'

  /**
   * @param {Cluster} cluster
   * @param {ClusterStats} stats 
   * @returns {google.maps.Marker} the created marker
   */
  render (cluster: Cluster, stats?: ClusterStats): google.maps.Marker {
    const color: string = this.getColor(cluster.markers!)
    const count: number = this.getCount(cluster.markers!)

    // create svg url with fill color
    const svg = window.btoa(`
      <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity="1" r="120" />    
      </svg>`)
    // create marker using svg icon
    const marker = new google.maps.Marker({
      position: cluster.position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(50, 50)
      },
      label: {
        text: String(count),
        color: 'rgba(255,255,255,1.1)',
        fontSize: '17px'
      },
      opacity: 0.8,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
    })

    google.maps.event.addListener(marker, 'mouseover', function () {
      marker.setOpacity(1)
    })
    google.maps.event.addListener(marker, 'mouseout', function () {
      marker.setOpacity(0.8)
    })

    return marker
  }

  /**
   * Checks if all markers have the same color.
   * If so: it returns that color.
   * Else: it returns the default color. 
   * @param {google.maps.Marker[]} markers The clustered markers
   * @returns {string} The color the cluster should have
   */
  private getColor (markers: google.maps.Marker[]): string {
    const sameColor: boolean = markers.every((marker: google.maps.Marker, index: number, markers: Array<google.maps.Marker>) => {
      if (index === 0) return true
      const prev: google.maps.Marker = markers[index - 1]
      return marker.get('groupColor') === prev.get('groupColor')
    })
    return (sameColor) ? markers[0].get('groupColor') : this.defaultColor
  }

  /**
   * Counts the markers without the invissible markers
   * @param {google.maps.Marker[]} markers 
   * @returns {number} The number active markers
   */
  private getCount (markers: google.maps.Marker[]): number {
    let count = 0
    markers.forEach((marker: google.maps.Marker) => {
      if (marker.getVisible()) { count++ }
    })
    return count
  }
}
