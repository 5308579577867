import { Marker } from "@/dataTypes";
import store from "@/store";

export default class WindowHandler {
  private map: google.maps.Map
  private infoWindow: google.maps.InfoWindow

  constructor(map: google.maps.Map) {
    this.map = map
    this.infoWindow = new google.maps.InfoWindow()
  }

  showInfoWindow(marker: google.maps.Marker, kaartId: string): void {
    this.infoWindow.close()
    const markerData = this.getMarkerData(kaartId)
    if (!markerData) {
      return
    }
    this.infoWindow.setContent(this.getContent(markerData))
    this.infoWindow.open({
      anchor: marker,
      map: this.map
    })
  }

  getMarkerData(kaartId: string): undefined|Marker {
    return store.state.markers.find(marker => marker.kaart_id === kaartId)
  }

  private getContent(markerData: Marker): string {
    if (markerData.project_url) {
      return `<a target="_BLANK" style="color:#457BB0" href="${markerData.project_url}">${markerData.titel}</a>`
    }
    return markerData.titel
  }
}
