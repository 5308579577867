
import ClusterMap from '@/maps/clusterMap'
import { computed } from '@vue/runtime-core';
import { useStore } from "vuex";
import { inject } from "vue";

export default {
  name: 'InfoHolder',
  setup () {
    const store = useStore()
    const maps: Promise<ClusterMap> = inject('maps')!
    const markers = computed(() => store.state.markers)

    let selectMarker = (kaart_id: string) => {}

    maps.then((map: ClusterMap) => {
      selectMarker = (kaart_id: string) => {
        store.dispatch('selectMarker', kaart_id)
        map.selectMarker(kaart_id)
      }
    })

    return {
      markers: markers,
      groups: computed(() => store.state.groups),
      selectMarker: (kaart_id: string) => selectMarker(kaart_id)
    }
  }
}
