import { get } from './api'
import { KavelKaartenGroupedResponse } from "../dataTypes";

export class KavelKaarten {
  private baseUri = 'https://www.kavelkaarten.nl/api/'
  private apiKey: string = process.env.VUE_APP_KAVELKAARTEN_API_KEY

  async getLocations(): Promise<KavelKaartenGroupedResponse> {
    const uri: string = this.getApiUrl()
    const locations = <KavelKaartenGroupedResponse> await get(`${uri}/list_grouped`)
    return locations
  }

  private getApiUrl (): string {
    return `${this.baseUri}/${this.apiKey}`
  }
}

export const groupsLoaded = new Promise((resolve) => {
  window.addEventListener('groups-loaded', resolve)
})
