import Markers from '@/maps/markers'
import mapStyle from '@/maps/mapStyle'
import { KavelKaarten } from '@/core/kavelKaarten'

const images = {
  lvcOutline: require('@/assets/images/lvc-outline.svg')
}

/**
 * Handles the map functionality for the cluster map
 */
export default class ClusterMap {
  private kv?: KavelKaarten
  private overlay?: any
  private markerHandler?: Markers
  private map?: google.maps.Map
  private selectedMarker?: google.maps.Marker
  private infoWindow?: google.maps.InfoWindow

  private mapOptions: google.maps.MapOptions

  constructor() {
    this.mapOptions = {
      zoom: 12,
      // center: new google.maps.LatLng(51.661204, 5.871009),
      center: new google.maps.LatLng(51.667, 5.80),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: 'greedy',
      // mapTypeControl: false,
      scrollwheel: false,
      panControl: false,
      styles: mapStyle
    }
  }

  /**
   * Initializes the cluster map async
   * @param {HTMLElement} mapElem The map element
   */
  async init(mapElem: HTMLElement) {
    try {
      const google = window.google
      // const { Overlay } = await import('@/maps/overlay')
      const { lvcPath } = await import('@/maps/polygonPath')
      this.map = new google.maps.Map(mapElem, this.mapOptions)
      const imageBounds: any =
        new google.maps.LatLngBounds(
          new google.maps.LatLng(51.551051, 5.687681), // bottom left
          new google.maps.LatLng(51.779173, 6.053187) // top right
        )
      // this.overlay = new Overlay(this.map, images.lvcOutline, imageBounds)
      this.markerHandler = new Markers(this.map)
      const polygonMask = new google.maps.Polygon({
        map: this.map,
        strokeColor: '#666666',
        strokeOpacity: 1,
        strokeWeight: 4,
        fillColor: '#000000',
        fillOpacity: 0.4,
        paths: lvcPath
      })
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * As the name of the method describes: Creates a marker group
   * @param {string} groupName The name of the group
   * @param {string} color the color the markers in the group should have
   * @param {any[]} markers an array with latitudes and longitudes of the markers
   */
  createMarkerGroup(groupName: string, color: string, markers: any[]): void {
    this.markerHandler?.addGroup(groupName, color)
    markers.forEach((markerdata: any) => {
      this.markerHandler?.addMarker(markerdata, groupName)
    })
  }

  cluster(): void {
    this.markerHandler?.clusterMarkers()
  }

  /**
   * Toggles the visibility of all markers in a group
   * @param {string} groupName The name of the group
   */
  toggleGroup(groupName: string) {
    if (this.markerHandler) {
      this.markerHandler.toggleGroup(groupName)
    }
  }

  getMap(): google.maps.Map {
    if (this.map) {
      return this.map
    }
    throw new Error("Map not set");
  }

  getMarkersInViewport(): string[] {
    if (this.map && this.markerHandler) {
      const mapsInViewport: string[] = []
      const bounds: google.maps.LatLngBounds | undefined = this.map.getBounds()
      const markers: google.maps.Marker[] = this.markerHandler.getActiveMarkers()
      markers.forEach((marker: google.maps.Marker) => {
        const markerPos = marker.getPosition()
        if (markerPos && bounds?.contains(markerPos) === true) {
          mapsInViewport.push(marker.get('kaartId'))
        }
      })
      return mapsInViewport
    }
    return []
  }

  selectMarker(kaartId: string): void {
    this.markerHandler?.selectMarker(kaartId)
  }
}
