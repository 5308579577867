/**
 * Initiializes the maps module as a vue plugin
 */
import loadGoogleMaps from './init'
import ClusterMap from './clusterMap'

export default {
  install: (app: any, options: any) => {
    const maps = new Promise(async (resolve, reject) => {
      await loadGoogleMaps()
      resolve(new ClusterMap())
    })
    app.provide('maps', maps)
  }
}
