import { ActionContext, createStore } from 'vuex'
import { Marker, KavelKaartItem } from "../dataTypes";

interface State {
  groups: {
    [key: string]: {
      name: string,
      color: string,
      active: boolean
    }
  },
  markers: Marker[]
}

export default createStore<State>({
  state: {
    groups: {},
    markers: []
  },
  getters: {
  },
  mutations: {
    toggleGroup: (state: State, groupName: string) => {
      state.groups[groupName].active = !state.groups[groupName].active
    },
    addFilterGroup: (state: State, params: any) => {
      state.groups[params.groupName] = {
        name: params.groupName,
        color: params.color,
        active: true
      }
    },
    addMarkers: (state: State, { items, groupName }: { items: KavelKaartItem[], groupName: string, color: string }): void => {
      if (items) {
        items.forEach((item: KavelKaartItem) => {
          state.markers.push({
            kaart_id: item.kaart_id,
            omschrijving: item.omschrijving,
            project_url: item.project_url || item.external_url,
            titel: item.titel,
            groupName: groupName,
            active: true,
            selected: false
          })
        })
      }
    }
  },
  actions: {
    sortMarkers: (context: ActionContext<State, State>) => {
      context.state.markers.sort((a: Marker, b: Marker): number => {
        const A: string = a.titel.toUpperCase()
        const B: string = b.titel.toUpperCase()
        if (A < B) return -1
        if (A > B) return 1
        return 0
      })
    },
    setActiveMarkers: (context: ActionContext<State, State>, activeMarkers: string[]) => {
      context.state.markers.forEach((marker: Marker) => {
        if (activeMarkers.includes(marker.kaart_id)) {
          marker.active = true
        } else {
          marker.active = false
        }
      })
    },
    selectMarker: (context: ActionContext<State, State>, kaartId: string) => {
      context.state.markers.forEach((marker: Marker) => {
        if (marker.kaart_id == kaartId) {
          marker.selected = true
          const markerElement: Element = document.getElementsByClassName(`marker-${marker.kaart_id}`)[0]
          const markerHolder: null|Element = document.querySelector('#viewport-markers .markers')
          markerElement.scrollIntoView({ behavior: "smooth", block: 'start' });
        } else {
          marker.selected = false
        }
      })
    }
  },
  modules: {
  }
})
