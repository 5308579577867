import { MarkerClusterer, Cluster } from '@googlemaps/markerclusterer'
import ClusterRenderer from './clusterRenderer'
import store from '../store'
import WindowHandler from './windowHandler'

interface MarkerGroup {
  [key: string]: {
    color: string,
    active: boolean,
    markers: {
      [key: string]: google.maps.Marker
    }
  }
}

/**
 * SVG path notation
 * @see https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
 */
const markerIcon = 'M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z'

/**
 * Handles te markers and the clustering of them
 */
export default class Markers {
  private map: google.maps.Map
  private markerGroups: MarkerGroup
  private markerClusterer?: MarkerClusterer
  private selectedMarker?: google.maps.Marker
  private windowHandler: WindowHandler

  constructor(map: google.maps.Map) {
    this.map = map
    this.markerGroups = {}
    this.windowHandler = new WindowHandler(map)
  }

  /**
   * Adds a marker to the group
   * @param latLng The cooridinates where de marker should be placed
   * @param {string} groupName The name of the group
   */
  addMarker(markerdata: any, groupName: string) {
    if (!this.hasGroup(groupName)) {
      throw `Groupname: '${groupName}' doesn't exist`
    }

    const icon = {
      path: markerIcon,
      anchor: new google.maps.Point(11, 22),
      fillOpacity: 1,
      fillColor: this.markerGroups[groupName].color,
      strokeWeight: 1,
      strokeColor: '#fff',
      scale: 2
    }
    const marker = new google.maps.Marker({
      map: this.map,
      position: markerdata.LatLng,
      icon: icon
    })
    marker.set('groupColor', this.markerGroups[groupName].color)
    marker.set('kaartId', markerdata.kaart_id)
    marker.addListener("click", () => {
      const kaartId: string = marker.get('kaartId')
      this.selectMarker(kaartId)
      store.dispatch('selectMarker', kaartId)
    })
    this.markerGroups[groupName].markers[markerdata.kaart_id] = marker
  }

  /**
   * Adds a group
   * @param {string} groupName The name of the group
   * @param {string} color The color it should have
   */
  addGroup(groupName: string, color: string) {
    if (this.hasGroup(groupName)) {
      throw `Groupname: '${groupName}' already exists`
    }
    this.markerGroups[groupName] = {
      color: color,
      active: true,
      markers: {}
    }
  }

  /**
   * Gets a group with its markers
   * @param {string} groupName The name of the group
   * @returns {MarkerGroup} The marker group
   */
  getGroup(groupName: string): MarkerGroup {
    if (!this.hasGroup(groupName)) {
      throw new Error(`Groupname: '${groupName}' doesn't exist`)
    }
    return this.markerGroups
  }

  /**
   * Toggles the visibility of the group
   * @param {string} groupName The name of the group
   */
  toggleGroup(groupName: string) {
    const active: boolean = this.markerGroups[groupName].active
    this.markerGroups[groupName].active = !active
    for (const kaartId in this.markerGroups[groupName].markers) {
      this.markerGroups[groupName].markers[kaartId].setVisible(!active)
    }
    // this.markerGroups[groupName].markers.forEach(marker => {
    //   marker.setVisible(!active)
    // })
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers()
      const markers = this.getActiveMarkers()
      this.markerClusterer.addMarkers(markers)
    }
  }

  /**
   * Checks if a group exists
   * @param {string} groupName The name of the group
   * @returns {boolean} true/false
   */
  hasGroup(groupName: string): boolean {
    return groupName in this.markerGroups
  }

  /**
   * Starts the marker clustering proces
   */
  clusterMarkers() {
    const markers = this.getActiveMarkers()
    this.markerClusterer = new MarkerClusterer({
      map: this.map,
      markers: markers,
      onClusterClick: this.onClusterClick,
      renderer: new ClusterRenderer()
    })
  }

  /**
   * Handles the cluster click event
   * @param {google.maps.MapMouseEvent} event The click event
   * @param {Cluster} cluster The cluster that is clicked
   * @param {google.maps.Map} map The corresponding map
   */
  private onClusterClick(event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) {
    const maxZoom = 18
    map.panTo(event.latLng!)
    const bounds: google.maps.LatLngBounds = cluster.bounds!
    this.map.fitBounds(bounds, {
      bottom: 100,
      left: 100,
      right: 100,
      top: 100
    })
    if (this.map.getZoom()! > maxZoom) {
      this.map.setZoom(maxZoom)
    }
  }

  /**
   * Gets all markers from the active groups
   * @returns {google.maps.Marker[]} An array with the active markers
   */
  getActiveMarkers(): google.maps.Marker[] {
    let markers: google.maps.Marker[] = []
    for (const groupName in this.markerGroups) {
      if (this.markerGroups[groupName].active === true) {
        markers = markers.concat(Object.values(this.markerGroups[groupName].markers))
      }
    }
    return markers
  }

  getMarkerByKaartId(kaartId: string): google.maps.Marker | false {
    for (const groupName in this.markerGroups) {
      if (this.markerGroups[groupName].markers[kaartId]) {
        return this.markerGroups[groupName].markers[kaartId]
      }
    }
    return false;
  }

  selectMarker(kaartId: string): void {
    if (this.selectedMarker) {
      this.selectedMarker.setAnimation(null)
    }
    let newSelected = this.getMarkerByKaartId(kaartId)
    if (newSelected) {
      this.selectedMarker = newSelected
      this.selectedMarker.setAnimation(google.maps.Animation.BOUNCE)
    }
    if (typeof this.selectedMarker !== 'undefined') {
      this.windowHandler.showInfoWindow(this.selectedMarker, kaartId)
    }
  }
}
