/**
 * Loads the google maps api
 */
declare const window: any
declare const document: any

const CALLBACK_NAME = 'gmapsCallback'

let resolveInitPromise: any
let rejectInitPromise: any
let initialized = !!window.google

/**
 * The empty promise that will be resolved when google maps is loaded
 */
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve
  rejectInitPromise = reject
})

/**
 * Loads google maps
 * @returns {Promise} An empty promise
 */
export default function loadGoogleMaps () {
  if (initialized) return initPromise

  initialized = true
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google)

  const script = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAPS_API_KEY}&callback=${CALLBACK_NAME}`
  script.onerror = rejectInitPromise
  document.querySelector('head').appendChild(script)

  return initPromise
}
